<template>
    <div class="footer">
        <div class="footer-body">
            <div class="footer-top">
                <router-link :to="'/privacy'">Privacy Policy</router-link>
                |
                <router-link :to="'/aboutus'">About Us</router-link>
                |
                <router-link :to="'/terms'">Terms of Use</router-link>
            </div>
            <div class="footer-mid"></div>
            <div class="footer-top">
                <a href="https://www.facebook.com/" target="_blank">Facebook</a>
                |
                <a href="https://www.twitter.com/" target="_blank">Twitter</a>
            </div>
            <div class="footer-bom">
                Copyright © 2024 &emsp; Email:onlineforumz@gmail.com
            </div>
        </div>
    </div>
</template>

<script>
    export default {
    }
</script>

<style lang="less" scoped>
.footer{
    background: #1EDCB4;
    margin-top: 2em;
    padding: 2em 0;
    .footer-body{
        width: 80%;
        margin: 0 auto;
        text-align: center;
        font-size: 12px;
        color: #000;
        .footer-top{
            margin: 10px 0;
        }
    }
    .footer-mid{
        width: 50%;
        margin: 0 auto 10px;
        border-top: 1px solid #eee
    }
}
a{
    color: #000;
    text-decoration: none;
    margin: 0 10px;
    &:hover{
        text-decoration: underline;
    }
}
</style>